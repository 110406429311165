import React from "react";
import feature__bg from "../assets/img/feature/feature-bg.png";
import feature from "../assets/img/feature/feature.png";
const Feature = () => {
	return (
		<section
			className="feature-section"
			style={{
				background: `url(${feature__bg}) no-repeat center center / cover`,
			}}
			id="sandbox"
		>
			<div className="container">
				<div className="row justify-content-between align-items-center">
					<div className="col-lg-6">
						<div className="feature-content">
							<div className="section-title mb-lg-0 text-white">
								<h2 className="title text-white">
									Powerfull easy to use APIs
								</h2>
								<p>
									Perfect balance between abstraction and provided
									functionality. Write scripts that are able to execute
									actions on any connected exchange or blockchain
									directly.
								</p>
								<a href="https://github.com/Deltaport-io/deltaport/blob/main/client/src/Main.md#bot-sandbox" className="btn btn--primary">
									Bot APIs
								</a>
								&nbsp;
								<a href="https://github.com/Deltaport-io/deltaport/blob/main/client/src/Main.md#rest-apis" className="btn btn--primary">
									REST APIs
								</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="feature-img">
							<img src={feature} alt="feature" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Feature;
