import React from "react";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import sponsors from "../assets/data/sponsors";

const Sponsor = () => {
	return (
		<section className="sponsor-section">
			<div className="container">
				<Swiper
					spaceBetween={50}
					autoplay
					breakpoints={{
						0: {
							slidesPerView: 4,
						},
						540: {
							slidesPerView: 5,
						},
						768: {
							slidesPerView: 6,
						},
						992: {
							slidesPerView: 7,
						},
						1200: {
							slidesPerView: 7,
						},
					}}
				>
					{sponsors.map(({ sponsor }, i) => (
						<SwiperSlide key={i}>
							<div className="sponsor-item">
								<img src={sponsor} height={50} alt="sponsor" />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default Sponsor;
