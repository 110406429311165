import React from "react";

import About from "./components/About";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Feature from "./components/Feature";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Sponsor from "./components/Sponsor";

function App() {
	return (
		<>
			<Header />
			<Banner />
			<Sponsor />
			<About />
			<Feature />
			<Contact />
			<Footer />
		</>
	);
}

export default App;
