import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as any);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
