import React from "react";
import { BsTwitter } from "react-icons/bs";
import logo from "../assets/img/logo-big.png";
const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-logo-area">
						<a href="/" className="footer-logo">
							<img src={logo} alt="logo" />
						</a>
						<div>
							Open Source / Self hosted / DeFi, GameFi Trading Platform
						</div>
					</div>
					<div className="footer-link-wrapper">
						<div className="footer-link">
							<h6 className="footer-title">Links</h6>
							<ul className="links">
								<li>
									<a href="#home">Home</a>
								</li>
								<li>
									<a href="#contact">Contact</a>
								</li>
							</ul>
						</div>
						<div className="footer-link">
							<h6 className="footer-title">Resources</h6>
							<ul className="links">
								<li>
									<a href="/privacy.html">Privacy Policy</a>
								</li>
								<li>
									<a href="/terms.html">Terms & Conditions</a>
								</li>
							</ul>
						</div>
						<div className="footer-link">
							<h6 className="footer-title">Social Links</h6>
							<ul className="social-icons">
								<li>
									<a href="https://twitter.com/Deltaport_io">
										<BsTwitter />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
