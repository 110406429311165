import React from "react";
import banner__shape__2 from "../assets/img/banner/banner-shape-2.png";
import banner__shape from "../assets/img/banner/banner-shape1.png";
import banner__thumb from "../assets/img/banner/banner-thumb.png";
const Banner = () => {
	return (
		<section
			className="banner"
			style={{
				background: `url(${banner__shape__2}) no-repeat center center / cover`,
			}}
			id="home"
		>
			<div
				className="banner-inner"
				style={{
					background: `url(${banner__shape}) no-repeat center center / cover`,
				}}
			>
				<div className="container">
					<div className="banner-wrapper">
						<div className="banner-content">
							<h2 className="title">
								Automation Platform for The Blockchain
							</h2>
							<p>
								Automate trading on over 50 exchanges and ethereum smart
								contracts directly, all through one platform!
							</p>
							<div className="btn--grp">
								<a href="https://demo.deltaport.io/login?ssid=1823375daa7.5MhliuKvWyFIrseSdIjJtyuJMEYecpJLn6LnQ4dn9VfAGd3oEeXbUpG/2np53d4d" target="_blank" rel="noreferrer" className="btn btn--primary mb-4">
									Web Demo
								</a>
							</div>
							<div>
								<div className="title mb-2">Download Desktop Client</div>
								<a href="https://github.com/Deltaport-io/deltaport/releases/download/v2.0.0/Deltaport_2.0.0_Setup.exe" className="btn btn--primary btn-sm me-2">
										Windows
								</a>
								<a href="https://github.com/Deltaport-io/deltaport/releases/download/v2.0.0/Deltaport_2.0.0_Setup.dmg" className="btn btn--primary btn-sm me-2">
										macOS
								</a>
								<a href="https://github.com/Deltaport-io/deltaport/releases/download/v2.0.0/Deltaport_2.0.0_Setup.deb" className="btn btn--primary btn-sm me-2">
										Linux
								</a>
							</div>
						</div>
						<div className="banner-thumb">
							<img src={banner__thumb} alt="banner" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
