import img1 from "../img/sponsor/aave-aave-logo.svg";
import img2 from "../img/sponsor/bitcoin-btc-logo.svg";
import img4 from "../img/sponsor/ethereum-eth-logo.svg";
import img5 from "../img/sponsor/litecoin-ltc-logo.svg";
import img6 from "../img/sponsor/polygon-matic-logo.svg";
import img7 from "../img/sponsor/the-graph-grt-logo.svg";
import img8 from "../img/sponsor/uniswap-uni-logo.svg";

const sponsors = [
	{
		sponsor: img1,
	},
	{
		sponsor: img4,
	},
	{
		sponsor: img7,
	},
	{
		sponsor: img8,
	},
	{
		sponsor: img6,
	},
	{
		sponsor: img2,
	},
	{
		sponsor: img5,
	},
];

export default sponsors;
