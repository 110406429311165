import React from "react";
import { BsCheck2Circle } from "react-icons/bs";
import img1 from "../assets/img/about/about1.png";
import img2 from "../assets/img/about/about2.png";
const About = () => {
	return (
		<section className="about-section" id="about">
			<div className="container">
				<div className="row">
					<div className="col-md-9 col-lg-8 col-xl-8">
						<div className="section-title">
							<h2 className="title">
								Fully integrated suite for decentralised finance
							</h2>
							<p>
								Trade on over 50 exchanges and ethereum directly. Add
								your crypto wallet and use it for direct transfers of
								tokens, swap over pools, automated trading. Fully under
								your control from downloadable desktop app or
								self-hosted on your own servers as a web app.
							</p>
							<a href="https://demo.deltaport.io/login?ssid=1823375daa7.5MhliuKvWyFIrseSdIjJtyuJMEYecpJLn6LnQ4dn9VfAGd3oEeXbUpG/2np53d4d" target="_blank" rel="noreferrer" className="btn btn--primary">
								Web Demo
							</a>
						</div>
					</div>
				</div>
				<div className="row g-4" id="features">
					<div className="col-md-6">
						<div className="about-item">
							<div className="about-img">
								<a href="/#">
									<img src={img1} alt="about" />
								</a>
							</div>
							<div className="about-content">
								<h5 className="title">
									<a href="/#">Exchanges, Wallets</a>
								</h5>
								<p>Easily import and connect your wallets, exchanges</p>
								<ul>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>Unlimited wallets, exchanges</span>
									</li>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>Non-custodial wallets you control</span>
									</li>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>50+ available exchanges</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="about-item">
							<div className="about-img mb-4 pb-md-2 pb-lg-4">
								<a href="/#">
									<img src={img2} alt="about" />
								</a>
							</div>
							<div className="about-content">
								<h5 className="title">
									<a href="/#">Pairs, Tokens, Pools</a>
								</h5>
								<p>Explore and trade on pairs, tokens, pools.</p>
								<ul>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>Retrieve balances</span>
									</li>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>Execute trades</span>
									</li>
									<li>
										<span className="icon">
											<BsCheck2Circle />
										</span>
										<span>Swap, transfer tokens</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
