import React, { Component } from 'react'

type ContactStates = {
  logError: string
  logErrors: any
	logSent: string
  logName: string
  logEmail: string
  logComments: string
	isLoading: any
};

export default class Contact extends Component <{}, ContactStates> {
	constructor (props: {}) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
			logSent: '',
      logName: '',
      logEmail: '',
      logComments: '',
			isLoading: false
    }
  }

  inputChange = (event: any) => {
		console.log(event)
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as ContactStates)
  }

  handleForm = (event: any) => {
		this.setState({
			logSent: '',
			logErrors: {},
			logError: '',
			isLoading: true
		})
    event.preventDefault()
    fetch(
      'https://pagebackend.deltaport.io/api/v1/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          name: this.state.logName,
					comments: this.state.logComments
        })
      }).then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
						logName: '',
						logEmail: '',
						logComments: '',
						logSent: json.message,
						isLoading: false
          })
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message,
						isLoading: false
          })
        }
      })
  }

	render () {
		return (
			<section className="contact-section" id="contact">
				<div className="contact-wrpper" id="consulting">
					<div className="section-title text-center">
						<span className="subtitle mb-3">Contact</span>
						<h2 className="title mb-0">Consulting & Contact</h2>
					</div>
					<form onSubmit={(e) => this.handleForm(e)}>
						<div className="row gx-3 gy-4">
							<div className="col-md-6">
								<label className="form-label" htmlFor="name">
									Name
								</label>
								<input
									name="logName"
									className="form-control"
									type="text"
									placeholder="Enter your name ..."
									onChange={this.inputChange}
									value={this.state.logName}
								/>
							</div>
							<div className="col-md-6">
								<label className="form-label" htmlFor="email">
									Email Address
								</label>
								<input
									name="logEmail"
									className="form-control"
									type="text"
									placeholder="Enter your email ..."
									onChange={this.inputChange}
									value={this.state.logEmail}
								/>
							</div>
							<div className="col-lg-12">
								<label className="form-label" htmlFor="message">
									Message
								</label>
								<textarea
									className="form-control"
									name="logComments"
									placeholder="Enter message..."
									onChange={this.inputChange}
									value={this.state.logComments}
								>
									{this.state.logComments}
								</textarea>
							</div>
							{this.state.logError ?
								<div className="col-lg-12">
									<div className="alert alert-danger text-center">
										{this.state.logError}
									</div>
								</div>
							: null}
							{this.state.logSent ?
								<div className="col-lg-12">
									<div className="alert alert-success text-center">
										{this.state.logSent}
									</div>
								</div>
							: null}
							{ this.state.isLoading ?
								<div className="col-lg-12 text-center">
									<button type="submit" className="btn btn--primary">
										<div className="spinner-border spinner-border-sm">
											<span className="sr-only"></span>
										</div>
									</button>
								</div>
							: 
								<div className="col-lg-12 text-center">
									<button type="submit" className="btn btn--primary">
										Submit
									</button>
								</div>
							}
						</div>
					</form>
				</div>
			</section>
		);
	}
};
