import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import menu__links from "../assets/data/menu-links";
import logo from "../assets/img/logo-big.png";
const Header = () => {
	const [headerFixed, setHederFixed] = useState(false);
	const [menuToggle, setMenuToggle] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 10) {
				setHederFixed(true);
			} else {
				setHederFixed(false);
			}
		});
	}, []);

	return (
		<header className={headerFixed ? "active" : ""}>
			<div className="container">
				<div className="header-wrapper">
					<div className="logo">
						<a href="/">
							<img src={logo} alt="img" />
						</a>
					</div>
					<div
						className={`menu-wrapper ${menuToggle ? " open-menu" : ""}`}
					>
						<div
							className="d-lg-none close-menu"
							onClick={() => setMenuToggle(!menuToggle)}
						>
							<GrClose />
						</div>
						<ul className="menu">
							{menu__links.map(({ title, link }, i) => (
								<li key={i}>
									<a href={link}>{title}</a>
								</li>
							))}
						</ul>
					</div>
					<a href="#contact" className="btn btn--white">
						Contact
					</a>
					<div
						className="toggle-icon d-lg-none"
						onClick={() => setMenuToggle(!menuToggle)}
					>
						<FaBars />
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
