const menu__links = [
	{
		title: "Home",
		link: "#home",
	},
	{
		title: "About",
		link: "#about",
	},
	{
		title: "Features",
		link: "#features",
	},
	{
		title: "Sandbox",
		link: "#sandbox",
	},
	{
		title: "Consulting",
		link: "#contact",
	},
];
export default menu__links;
